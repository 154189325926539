function HelpController($http, $location, $anchorScroll) {
  console.info('section', this.section);
  this.link = '';

  const baseLink = '/assets/help';

  this.updateLink = (section) => {
    if(!this.section && !section) {
      this.link = `${baseLink}/guide.md`;
       // $location.path(`/help`);
    } else {
      let prefix = section || this.section;
      prefix = prefix.replace(/(\.md)$/, '');

      // console.info('prefix', prefix);

      this.link = `${baseLink}/${prefix}.md`;
      // $location.path(`/help/${prefix}`).replace();
    }

    $anchorScroll();
  };

  this.$onChanges = (current) => {
    this.updateLink();
  };

}

angular.module('sq.help.component', ['btford.markdown']).component('sqHelp', {
  templateUrl: 'help/help.tpl.html',
  bindings: {
    'section': '<',
  },
  controller: HelpController,
});